<template>
    <div class="home-banner">
        <h1 class="index_title">
            欢迎来到鲸道智能输液后台系统
        </h1>
    </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
export default {
    data() {
        return {
            text: '',
            t2: ''
        }
    },
    created() {
        this.checkLogin()
    },
    methods: {
        checkLogin() {
            this.$post(domain + '/admin/v1/customer/customerList', this.query).then(res => {
                if (res.code !== 0) {
                    this.$router.push({
                        path: '/login',
                    })
                }
            })
        }
    },
}
</script>
<style lang="less">
.home-banner {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    bottom: 0;
    background: #fff;

    .index_title {
        position: absolute;
        text-align: center;
        width: 100%;
        top: 40%;
        transform: translateY(-50%);
    }
}
</style>